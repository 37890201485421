<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane :label="$t('tenant.dataBelong')" name="first">
      <batch-modify></batch-modify>
    </el-tab-pane>
    <el-tab-pane :label="$t('tenant.tenantManage')" name="second">
      <tenant-list></tenant-list>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
  import TenantList from "@/views/tenant/TenantList";
  import BatchModify from "@/views/tenant/BatchModify";

  export default {
    components: {BatchModify, TenantList},
    data() {
      return {
        activeName: "first",
      };
    },
    mounted() {

    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
